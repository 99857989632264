import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button } from "reactstrap";
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

class Products extends Component {
    constructor(props) {
        super(props)
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.notifRef = React.createRef()
        this.state = {
            isLoading: true,
            customersList: [],
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    loadCustomers() {
        return fetch('https://admin.mydzstore.com/api/customers-list', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ customersList: data.data, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //LIST PRODUCTS IN TABLE
    listCustomers() {
        if (this.state.customersList.length === 0) {
            return (
                <p>Vous n'avez encore aucun client</p>
            )
        } else {
            return (
                <Table >
                    <thead className="text-primary">
                        <tr>
                            <th>ID USER</th>
                            <th>nom</th>
                            <th>prenom</th>
                            <th>Boutique</th>
                            <th>Plan</th>
                            <th>Expiration</th>
                            <th>Site Web</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.customersList.map((value, index) => {
                            let domainWarn = (value.domain_name !== "" && value.domain_name !== null && value.domain_name_enabled === "0") ? 'text-danger' : ''
                            const curDate = new Date();
                            const expiryDate = new Date(value.offre_exp)
                            let expiryWarn = curDate > expiryDate ? 'text-danger' : ''

                            return (
                                <tr key={index} className={domainWarn + ' ' + expiryWarn}>
                                    <td>{value.id_user}</td>
                                    <td>{value.nom}</td>
                                    <td>{value.prenom}</td>
                                    <td>{value.store_name}</td>
                                    <td>{value.offre}</td>
                                    <td>{value.offre_exp_h}</td>
                                    <td>{value.domain_name}</td>
                                    <td className="text-right">
                                        <Link to={
                                            {
                                                pathname: "/customers/customers-form/" + value.id_user,
                                                id_user: value.id_user
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                <i className="nc-icon nc-zoom-split"></i>
                                            </Button>
                                        </Link>
                                        <Button className="btn-sm btn-round btn-icon btn-outline-danger mr-2" onClick={() => this.removeUser(value.id_user)}>
                                            <i className="nc-icon nc-simple-remove"></i>
                                        </Button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    //REMOVE PRODUCT BY ID
    removeUser(id_user) {
        return fetch('https://admin.mydzstore.com/api/customers-remove', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_user: id_user
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.loadCustomers()
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadCustomers()
    }

    render() {
        const dirLang = this.lang === "ar" ? "arabic" : ""
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row className={dirLang}>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Liste des clients</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listCustomers()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Products;
