import Dashboard from "views/Dashboard.js";
import CustomersList from "views/CustomersList";
import CustomersForm from "views/CustomersForm";

var routes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    name_ar: "لوحة القيادة",
    icon: "nc-icon nc-layout-11",
    component: Dashboard,
  },
  {
    path: "/customers",
    name: "Mes clients",
    name_ar: "عملائي",
    icon: "nc-icon nc-tag-content",
    component: CustomersList,
  },
  {
    path: "/customers/customers-form/:id_user",
    name: "Fiche Client",
    icon: "nc-icon nc-tag-content",
    component: CustomersForm,
    display: false
  },
];
export default routes;
