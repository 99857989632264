import React, { Component } from 'react';
import { Row, Col, FormGroup, Input, CustomInput, Button } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import '../assets/css/myshop.css';
import Select from 'react-select'

class CustomersForm extends Component {
  constructor(props) {
    super(props)
    this.notifRef = React.createRef()
    this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
    this.id_user = this.props.match.params.id_user
    this.form = {}
    this.state = {
      plansList: [
        { value: "Limited", label: "Limited" },
        { value: "Store", label: "Store" },
        { value: "Pro", label: "Pro" },
        { value: "Business", label: "Business" },
      ],
      plan: {},
      userData: [],
      subState: false,
      domainState: false,
    }
  }
  //SHOW NOTIFICATION FUNCTION
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }
  //HANDLE SWITCH OPTIONS
  handleSwitch(event) {
    if (event.target.id === "sub_domain") {
      this.setState({ subState: !this.state.subState })

    } else if (event.target.id === "domain_name_enabled") {
      this.setState({ domainState: !this.state.domainState })
    }
  }
  setFormData(event) {
    if (event.value !== undefined) {
      this.form["plan"] = event.value
      if (event.value === "") {
        delete this.form["plan"]
      }
    } else {
      this.form[event.target.id] = event.target.value
      if (event.target.value === "") {
        delete this.form[event.target.id]
      }
    }
  }
  //CHANGE PLAN
  changePlan = () => {

    return fetch('https://admin.mydzstore.com/api/customers-migrate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_token: window.sessionStorage.getItem('session_token'),
        id_user: this.id_user,
        form: this.form
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        this.showNotification(data)
      })
      .catch((error) => console.error(error));
  }
  //LOAD CUSTOMER DATA
  loadCustomerData() {
    return fetch('https://admin.mydzstore.com/api/customers-detail', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_token: window.sessionStorage.getItem('session_token'),
        id_user: this.id_user
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "true") {
          let sub = data.data.sub_domain === "1" ? true : false
          let domain = data.data.domain_name_enabled === "1" ? true : false

          this.setState({ userData: data.data, plan: { value: data.data.offre, label: data.data.offre }, subState: sub, domainState: domain, isLoading: false })

        } else if (data.reponse === "false") {
          this.showNotification(data)
        }
        else {
          this.setState({ isLoading: false })
        }
      })
      .catch((error) => console.error(error));
  }

  //ENABLE SUB DOMAIN
  enableSubDomain(event) {
    return fetch('https://admin.mydzstore.com/api/customers-subdomain-enable', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_token: window.sessionStorage.getItem('session_token'),
        id_user: this.id_user,
        query: event.target.checked
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        this.showNotification(data)
      })
      .catch((error) => console.error(error));
  }
  //ENABLE DOMAIN
  enableDomain(event) {
    return fetch('https://admin.mydzstore.com/api/customers-domain-enable', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_token: window.sessionStorage.getItem('session_token'),
        id_user: this.id_user,
        query: event.target.checked
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        this.showNotification(data)
      })
      .catch((error) => console.error(error));
  }

  componentDidMount() {
    this.loadCustomerData()
  }
  render() {
    const dirLang = this.lang === "ar" ? "arabic" : ""
    return (
      <div className="content">
        <NotificationAlert ref={this.notifRef} />
        <Row className={dirLang}>
          <Col md="6">
            <FormGroup>
              <CustomInput type="switch" id="sub_domain" name="sub_domain" label="Sous-domaine" inline onChange={(event) => { this.handleSwitch(event); this.enableSubDomain(event) }} checked={this.state.subState} />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <CustomInput type="switch" id="domain_name_enabled" name="domain_name_enabled" label="Nom de domaine" inline onChange={(event) => { this.handleSwitch(event); this.enableDomain(event) }} checked={this.state.domainState} />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="id_user">
                ID User
              </label>
              <Input defaultValue={this.state.userData.id_user} type="text" id="id_user" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="nom">
                Nom
              </label>
              <Input defaultValue={this.state.userData.nom} type="text" id="nom" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="prenom">
                Prénom
              </label>
              <Input defaultValue={this.state.userData.prenom} type="text" id="prenom" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="tel">
                Téléphone
              </label>
              <Input defaultValue={this.state.userData.tel} type="text" id="tel" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="email">
                E-mail
              </label>
              <Input defaultValue={this.state.userData.email} type="text" id="email" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="dbname">
                Base de données
              </label>
              <Input defaultValue={this.state.userData.dbname} type="text" id="dbname" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="store_name">
                Boutique
              </label>
              <Input defaultValue={this.state.userData.store_name} type="text" id="store_name" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="date_heure">
                Date d'inscription
              </label>
              <Input defaultValue={this.state.userData.date_heure} type="text" id="date_heure" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="store_type">
                Activité
              </label>
              <Input defaultValue={this.state.userData.store_type} type="text" id="store_type" />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="offre">
                Plan
              </label>
              <Select value={this.state.plan} options={this.state.plansList} onChange={(event) => { this.setState({ plan: event }); this.setFormData(event) }} isSearchable={false} />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label htmlFor="offre_exp">
                Date d'expiration
              </label>
              <Input defaultValue={this.state.userData.offre_exp} type="date" id="offre_exp" onChange={(event) => this.setFormData(event)} />
            </FormGroup>
          </Col>
          <Col md="12">
            <Button onClick={() => this.changePlan()} className="btn btn-success btn-round">Valider</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CustomersForm;
